// TODO: fix eslint errors
/* eslint-disable */
import Modal, { ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { xcss, Box } from '@atlaskit/primitives'
import VideoRecorder from './VideoRecorder'
import { useState } from 'react'
import { INTERVIEWS } from '../../../../../utils/constants/api'
import { LongTextTooltip } from 'components/UI/LongTextTooltip'
import QuestionsBlock from './QuestionsBlock'
import { token } from '@atlaskit/tokens'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { useFlags } from '@atlaskit/flag'

export default function ModalContent4({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void
  setIsOpen: (arg: boolean) => void
  interviesID: string
}) {
  const [videoURL, setVideoURL] = useState<string>('')
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null)
  const [checkpointState, setCheckpointState] = useState(1)

  const [sending, setSending] = useState(false)

  const allNotChecked = !(checkpointState === 7 && videoBlob)
  const { showFlag } = useFlags()

  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            })}
          >
            Personality analysis by AI
          </Box>
        </ModalTitle>
      </ModalHeader>
      <Box
        xcss={xcss({
          paddingLeft: 'space.300',
          paddingRight: 'space.300',
        })}
      >
        <Box>
          <QuestionsBlock checkpointState={checkpointState} setCheckpointState={setCheckpointState} />

          <Box xcss={xcss({ marginTop: 'space.100' })}>
            <VideoRecorder setVideoURL={setVideoURL} setVideoBlob={setVideoBlob} />
          </Box>
        </Box>
      </Box>
      <ModalFooter>
        <Button
          appearance="subtle"
          onClick={() => {
            setIsOpen(false)
            setContent(1)
          }}
        >
          Відмінити
        </Button>
        <LongTextTooltip
          content="Необхідно вибрати всі пункти та записати відео!"
          maxContentLength={allNotChecked ? 20 : 100}
          position="top"
        >
          <Button
            isLoading={sending}
            isDisabled={allNotChecked}
            appearance="primary"
            onClick={() => {
              if (videoBlob) {
                const formData = new FormData()
                formData.append('video', videoBlob, 'video.webm')
                setSending(true)
                fetch(`${INTERVIEWS}/${interviesID}`, {
                  method: 'POST',
                  body: formData,
                })
                  .then((response) => {
                    setSending(false)
                    if (response.ok) {
                      setContent(5)
                    } else {
                      showFlag({
                        description: response.status ? `Error:${response.status}` : '',
                        icon: <ErrorIcon label="Info" primaryColor={token('color.background.danger.bold')} />,
                        title: 'Something went wrong with AI analysis.',
                        isAutoDismiss: true,
                      })
                    }
                  })
                  .catch((error) => console.error('Error:', error))
              } else {
                console.log('video empty')
              }
            }}
          >
            Відправити результати
          </Button>
        </LongTextTooltip>
      </ModalFooter>
    </div>
  )
}
