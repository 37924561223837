// TODO: fix eslint errors
/* eslint-disable */
import '../index.scss'
import './DatePicker.scss'
import MaskedTextInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import 'react-datepicker/dist/react-datepicker.css'
import DatePickerPlugin from 'react-datepicker'
import React, { useEffect, useState } from 'react'

type DatePicker = {
  date: Date | null
  label?: string
  onChange: (value: any) => void
  onBlur?: (value: any) => void
  onClick?: (event: any) => void
  horizontal: boolean
  required: boolean
  error: string | null
  minDate: Date | null
  maxDate: Date | null
  disabled: boolean
  placeholder?: string
  disabledDates?: Date[]
  excludeWeekends?: boolean
  hideYear?: boolean
  id?: string
  className?: string
  highlightDates?: Date[]
}

function DatePicker(props: DatePicker) {
  const [clicked, setClicked] = useState<boolean>(false)
  const [previousValue, setPreviousValue] = useState<Date | null>(null)

  useEffect(() => {
    setPreviousValue(deepCopy(props.date))
  }, [])

  function deepCopy(obj: Date | null) {
    return obj ? new Date(obj.getTime()) : null
  }

  const filterWeekends = (date: Date) => {
    const day = date.getDay()
    return props.excludeWeekends ? day !== 0 && day !== 6 : true
  }

  const datePipe = props.hideYear ? createAutoCorrectedDatePipe('dd') : createAutoCorrectedDatePipe('dd.mm.yyyy')

  const dateMask = props.hideYear ? [/\d/, /\d/] : [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

  function handleClick(event: any) {
    if (props.onClick) {
      props.onClick(event)
    }
  }

  return (
    <div className={`input${props.horizontal ? ' horizontal-input' : ''}`}>
      {props.label ? (
        <label>
          {props.label}
          {props.required ? <span>*</span> : ''}
        </label>
      ) : null}
      <div className="input-container" onClick={handleClick}>
        <DatePickerPlugin
          id={props.id}
          autoComplete="off"
          className={props.className}
          disabled={props.disabled}
          selected={props.date ? props.date : null}
          onChange={(e) => {
            props.onChange(e)
            setClicked(true)
          }}
          calendarStartDay={1}
          fixedHeight
          dateFormat="dd.MM.yyyy"
          onBlur={(e) => {
            setClicked(false)
            if (props.onBlur) {
              props.onBlur(e.target.value)
            }
          }}
          highlightDates={props.highlightDates}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              const inputElement = e.target as HTMLInputElement | HTMLTextAreaElement
              const sanitizedValue = inputElement.value.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '')
              if (props.onBlur) {
                if (sanitizedValue != null) {
                  const pieces = sanitizedValue.split('.')
                  if (pieces.length === 3) {
                    const [dayPart, monthPart, yearPart] = pieces
                    const calendarDay = parseInt(dayPart, 10)
                    const calendarMonth = parseInt(monthPart, 10) - 1
                    const calendarYear = parseInt(yearPart, 10)

                    if (
                      calendarDay >= 1 &&
                      calendarDay <= 31 &&
                      calendarMonth >= 0 &&
                      calendarMonth <= 11 &&
                      calendarYear >= 1900 &&
                      calendarYear <= 2100
                    ) {
                      const newDate = new Date(calendarYear, calendarMonth, calendarDay)
                      props.onBlur(newDate)
                      setPreviousValue(deepCopy(newDate))
                    }
                  }
                } else {
                  props.onBlur(null)
                  setPreviousValue(null)
                }
              }
              if (props.id) {
                const element = document.getElementById(props.id)
                if (element) {
                  element.blur()
                }
              }
            }
            if (e.key === 'Escape') {
              if (props.onBlur) {
                props.date = previousValue
                if (props.id) {
                  const element = document.getElementById(props.id)
                  if (element) {
                    element.blur()
                  }
                }
              }
            }
          }}
          onCalendarOpen={() => {
            // props.error = null
          }}
          onCalendarClose={() => {
            setClicked(false)
          }}
          placeholderText={props.placeholder ? props.placeholder : 'dd.mm.yyyy'}
          customInput={<MaskedTextInput pipe={datePipe} mask={dateMask} autoComplete="off" />}
          maxDate={props.maxDate ? props.maxDate : null}
          minDate={props.minDate ? props.minDate : null}
          excludeDates={props.disabledDates}
          filterDate={filterWeekends}
        />
        {(() => {
          if (props.error && !clicked) {
            return (
              <div className="error">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z"
                    fill="#E05D44"
                  />
                </svg>
                <span>{props.error}</span>
              </div>
            )
          }
        })()}
      </div>
    </div>
  )
}

export default DatePicker
