// TODO: fix eslint errors
/* eslint-disable */
import React, { type Dispatch, FC, type SetStateAction, useEffect } from 'react'
import { useTimeOffBalance } from '../../../../API/profile/timeOffBalance/useTimeOffBalance'
import { useProfile } from '../../context'
import style from './TimeOffBalance.module.scss'
import styleParent from '../ShortInformationCard.module.scss'
import { TimeOffBalance } from './TimeOffBalance'
import cn from 'classnames'

interface TimeOffBalanceListProps {
  setIsNotEmptyTB: Dispatch<SetStateAction<boolean>>
}

export const TimeOffBalanceList: FC<TimeOffBalanceListProps> = ({ setIsNotEmptyTB }) => {
  const {
    idOpenProfile,
    setTimeOffBalanceOne,
    setTimeOffBalance,
    setEditAccruedDaysModal,
    updateTimeOffBalance,
    isAccessEditRole,
    isManagerOpenProfile,
  } = useProfile()
  const { fetchTimeOffBalance, reFetchTimeOffBalance, timeOffBalance, isLoading } = useTimeOffBalance(idOpenProfile)

  useEffect(() => {
    fetchTimeOffBalance().then((response) => {
      setTimeOffBalance(response)
    })
  }, [])

  useEffect(() => {
    if (updateTimeOffBalance) {
      reFetchTimeOffBalance().then((response) => {
        setTimeOffBalance(response)
      })
    }
  }, [updateTimeOffBalance])

  useEffect(() => {
    setIsNotEmptyTB(Boolean(timeOffBalance?.length) || isLoading)
  }, [timeOffBalance])

  if (!timeOffBalance || isLoading)
    return (
      <div>
        <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Balance:</div>

        <div>Loading...</div>
      </div>
    )

  if (!timeOffBalance.length) {
    return null
  }

  return (
    <div>
      <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Balance:</div>

      <div>
        <ul className={style.timeOffBalanceList}>
          {timeOffBalance.map((value) => (
            <li key={value.timeOffPolicyId}>
              <TimeOffBalance
                type={value.typeIcon}
                title={value.timeOffPolicyName}
                count={value.quantity}
                onClick={() => {
                  if (isAccessEditRole || isManagerOpenProfile) {
                    setEditAccruedDaysModal(true)
                    setTimeOffBalanceOne(value)
                  }
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
