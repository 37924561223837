import { FC, useEffect, useState, useCallback } from 'react'
import { notification } from 'antd'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import { Toggle } from 'components/UI/Toggle'
import { BlockInfoItem } from '../../../BlockInfo'
import { useProfile } from '../../../context'
import { useSetField } from 'API/profile/setField/useSetField'

export const TimeTracking: FC = () => {
  const { jobInfo, idOpenProfile, isAccessEditRole } = useProfile()
  const [checked, setChecked] = useState(jobInfo?.currentJobInfoValue.timeTracking || false)

  const { setField, isSuccess } = useSetField(idOpenProfile)

  const [api, contextHolder] = notification.useNotification()

  const openSuccessNotification = useCallback(() => {
    api.success({
      message: `You’ve ${checked ? 'enabled' : 'disabled'} time tracking`,
      description: `Time tracking will be ${checked ? 'enabled' : 'disabled'} starting from tomorrow.`,
      placement: 'bottomLeft',
      duration: 10,
    })
  }, [api, checked])

  useEffect(() => {
    if (isSuccess) {
      openSuccessNotification()
    }
  }, [isSuccess, openSuccessNotification])

  return (
    <BlockInfoItem title="Time tracking">
      {contextHolder}
      <div className={cn(style.inputWrapper, style.toggle)}>
        <Toggle
          isDisabled={!isAccessEditRole}
          isChecked={checked}
          onChange={(event) => {
            setChecked(event.target.checked)
            setField(['current-job-info', 'time_tracking', event.target.checked.toString()].join('/'))
          }}
        />
      </div>
    </BlockInfoItem>
  )
}
