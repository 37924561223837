import { FC } from "react";
import { Button } from "../../../UI/Button";
import FlagFilledIcon from "@atlaskit/icon/glyph/flag-filled";
import VidPauseIcon from "@atlaskit/icon/glyph/vid-pause";
import VidPlayIcon from "@atlaskit/icon/glyph/vid-play";
import {
  useEmployee,
  assertEmployee,
} from "../../../../contexts/EmployeeContext";
import { useCheckInOut } from "../../../../API/checkInOut/useCheckInOut";
import Spinner from "@atlaskit/spinner";
import Info from "@atlaskit/icon/glyph/info";
import { token } from "@atlaskit/tokens";
import { useFlags } from "@atlaskit/flag";
import style from "./CheckInOut.module.scss";

export const CheckInOut: FC = () => {
  const { showFlag } = useFlags();
  const { employee } = useEmployee();
  assertEmployee(employee);

  const {
    status,
    isInitLoading,
    isInitError,
    isSyncLoading,
    onWork,
    onBreak,
    checkIn,
    isCheckInLoading,
    pause,
    isPauseLoading,
    unpause,
    isUnpauseLoading,
    checkOut,
    isCheckOutLoading,
  } = useCheckInOut(employee.id);

  if (isInitError) return <div>Ops...</div>;

  return (
    <>
      {isInitLoading ? (
        <Spinner size={36} />
      ) : (
        <div className={style.checkInOut}>
          <div className={style.timeInfo}>
            {onWork && (
              <div className={style.timeInfoPoint}>
                <div>On work:</div>
                <div>{onWork}</div>
              </div>
            )}
            {onBreak && (
              <div className={style.timeInfoPoint}>
                <div>On break:</div>
                <div>{onBreak}</div>
              </div>
            )}
          </div>
          {(status === "init" || status === "check_out") && (
            <div>
              <Button
                onClick={() => {
                  !isSyncLoading &&
                    !isCheckInLoading &&
                    checkIn().then(() => {
                      showFlag({
                        description:
                          "You have started your workday. Have a good day!",
                        icon: (
                          <Info
                            label="Info"
                            primaryColor={token("color.icon.information")}
                          />
                        ),
                        title: "Hi!",
                        isAutoDismiss: true,
                      });
                    });
                }}
                appearance="primary"
                iconBefore={
                  isCheckInLoading ? (
                    <Spinner appearance="invert" size="medium" />
                  ) : (
                    <VidPlayIcon label="Start" />
                  )
                }
              >
                Start
              </Button>
            </div>
          )}
          {(status === "check_in" || status === "unpause") && (
            <>
              <div>
                <Button
                  onClick={() => {
                    !isCheckOutLoading &&
                      !isSyncLoading &&
                      !isPauseLoading &&
                      pause().then(() => {
                        showFlag({
                          description: "You have paused your workday.",
                          icon: (
                            <Info
                              label="Info"
                              primaryColor={token("color.icon.information")}
                            />
                          ),
                          title: "I hope to see you soon!",
                          isAutoDismiss: true,
                        });
                      });
                  }}
                  iconBefore={
                    isPauseLoading ? (
                      <Spinner size="medium" />
                    ) : (
                      <VidPauseIcon label="Pause" />
                    )
                  }
                >
                  Pause
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    !isPauseLoading &&
                      !isSyncLoading &&
                      !isCheckOutLoading &&
                      checkOut().then(() => {
                        showFlag({
                          description:
                            "You have completed your workday. Have a good one!",
                          icon: (
                            <Info
                              label="Info"
                              primaryColor={token("color.icon.information")}
                            />
                          ),
                          title: "See you soon. Bye!",
                          isAutoDismiss: true,
                        });
                      });
                  }}
                  appearance="primary"
                  iconBefore={
                    isCheckOutLoading ? (
                      <Spinner appearance="invert" size="medium" />
                    ) : (
                      <FlagFilledIcon label="Finish" />
                    )
                  }
                >
                  Finish
                </Button>
              </div>
            </>
          )}
          {status === "pause" && (
            <div>
              <Button
                onClick={() => {
                  !isSyncLoading &&
                    !isUnpauseLoading &&
                    unpause().then(() => {
                      showFlag({
                        description: "Welcome back after your pause.",
                        icon: (
                          <Info
                            label="Info"
                            primaryColor={token("color.icon.information")}
                          />
                        ),
                        title: "Glad to see you back!",
                        isAutoDismiss: true,
                      });
                    });
                }}
                iconBefore={
                  isUnpauseLoading ? (
                    <Spinner size="medium" />
                  ) : (
                    <VidPlayIcon label="Unpause" />
                  )
                }
              >
                Unpause
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
