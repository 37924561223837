import { useState, useCallback, useMemo } from 'react'
import { NoData, NoResults } from 'components/UI/table'
import { IAnnouncement } from 'models'
import Button from '@atlaskit/button/new'
import AddIcon from '@atlaskit/icon/glyph/add'
import {
  AnnouncementsTable,
  AnnouncementsTableFilters,
  AnnouncementsTableFiltersType,
  AnnouncementsTableType,
} from './components'
import {
  AnnouncementModalTypeEnum,
  announcementModalDefaultState,
  defaultAnnouncementsDate,
  defaultAnnouncementQuery,
} from './constants'
import { useGetAnnouncementsQuery, AnnouncementsQueryType } from 'services/api'
import { AnnouncementsStyles } from './style'
import { IAnnouncementsModalProps, GetAnnouncementModalType } from './types'
import { getAnnouncementModalType } from './helpers'
import { SkeletonTable } from 'components/UI/skeleton-table'

export const Announcements = () => {
  const [query, setQuery] = useState<AnnouncementsQueryType>(defaultAnnouncementQuery)
  const [announcementModalState, setAnnouncementModalState] =
    useState<IAnnouncementsModalProps>(announcementModalDefaultState)

  const { isOpen, payload, type: modalType } = announcementModalState
  const { page, size, order, sort } = query

  const { data: announcementsDate = defaultAnnouncementsDate, isLoading, isFetching } = useGetAnnouncementsQuery(query)

  const onOpenDeleteAnnouncementModal = useCallback(
    (announcement: IAnnouncement): void =>
      setAnnouncementModalState({
        isOpen: true,
        payload: announcement,
        type: AnnouncementModalTypeEnum.DELETE,
      }),
    [],
  )

  const onOpenEditAnnouncementModal = useCallback(
    (announcement: IAnnouncement): void =>
      setAnnouncementModalState({
        isOpen: true,
        payload: announcement,
        type: AnnouncementModalTypeEnum.EDIT,
      }),
    [],
  )

  const onOpenCreateAnnouncementModal = () =>
    setAnnouncementModalState({
      isOpen: true,
      payload: null,
      type: AnnouncementModalTypeEnum.CREATE,
    })

  const onClose = () =>
    setAnnouncementModalState({
      isOpen: false,
      payload: null,
      type: AnnouncementModalTypeEnum.CREATE,
    })

  const onUpdateTableFilters = (filters: AnnouncementsTableFiltersType) => {
    const { priority, locations, ...rest } = filters
    const priorityValues = priority.map(({ value }) => value)
    const locationValues = locations.map(({ value }) => value)

    const newQuery: AnnouncementsQueryType = {
      ...defaultAnnouncementQuery,
      ...rest,
      priority: priorityValues,
      locations: locationValues,
    }

    setQuery(newQuery)
  }

  const onUpdateTableState = (tableState: AnnouncementsTableType) => {
    const newQuery: AnnouncementsQueryType = {
      ...query,
      ...tableState,
    }
    setQuery(newQuery)
  }

  const onDeleteAnnouncement = useCallback(() => {
    if (announcementsDate?.items.length === 1 && page > 1) {
      const newQuery = {
        ...query,
        page: page - 1,
      }

      return setQuery(newQuery)
    }
  }, [announcementsDate.items.length])

  const AnnouncementModal = useMemo(() => {
    const announcementModalType: GetAnnouncementModalType = getAnnouncementModalType({
      onClose,
      payload,
      onDeleteAnnouncement,
    })

    return announcementModalType[modalType]
  }, [modalType])

  const hasActiveFilters = useMemo(() => {
    const { priority, locations, search } = query

    return !!(priority.length || locations.length || search)
  }, [query.locations, query.priority, query.search])

  return (
    <AnnouncementsStyles>
      {isLoading && <SkeletonTable filtersCount={3} colCount={5} rowCount={5} />}
      <div className="table-header">
        <h3 className="table-header-title">Announcements</h3>
        <div className="table-filters">
          <AnnouncementsTableFilters onUpdateTableFilters={onUpdateTableFilters} />
          <Button
            appearance="primary"
            onClick={onOpenCreateAnnouncementModal}
            iconBefore={(iconProps) => <AddIcon {...iconProps} size="small" />}
          >
            Create new
          </Button>
        </div>
      </div>
      {announcementsDate.total ? (
        <AnnouncementsTable
          sourceData={announcementsDate.items}
          onDelete={onOpenDeleteAnnouncementModal}
          onEdit={onOpenEditAnnouncementModal}
          onUpdateTableState={onUpdateTableState}
          total={announcementsDate.total}
          loading={isFetching}
          showPagination={announcementsDate.total > size}
          tableState={{
            page,
            size,
            sort,
            order,
          }}
        />
      ) : (
        <>
          {hasActiveFilters ? (
            <NoResults />
          ) : (
            <NoData title="There are no announcements here yet" onClick={onOpenCreateAnnouncementModal} />
          )}
        </>
      )}
      {isOpen && AnnouncementModal}
    </AnnouncementsStyles>
  )
}
