import { Inline, Box, xcss } from "@atlaskit/primitives";
import Avatar from "@atlaskit/avatar";
import { Link } from "react-router-dom";
import {
  StyledAmount,
  UserBlock,
  StyledUserBlockInfo,
} from "../TimeOffLogDrawerStyles";
import { sampleEmployees } from "./employees";

const createTableHead = (withWidth: boolean) => ({
    cells: [
      {
        key: "date",
        content: "Date",
        isSortable: true,
        width: withWidth ? 10 : undefined,
      },
      {
        key: "amount",
        content: "Amount",
        isSortable: true,
        width: withWidth ? 10 : undefined,
      },
      {
        key: "changedBy",
        content: "Changed by",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 12 : undefined,
      },
      {
        key: "balance",
        content: "Balance",
        isSortable: true,
        width: withWidth ? 10 : undefined,
      },
      {
        key: "comment",
        content: "Comment",
        isSortable: true,
        width: withWidth ? 20 : undefined,
      },
    ],
  });

export const tableHead = createTableHead(true);

function determineAmountType(amount: string): string {
  if (/^[+-]?\d+(\.\d+)?$/.test(amount)) {
    const number = parseInt(amount, 10);

    if (number > 0) {
      return "positive";
    } if (number < 0) {
      return "negative";
    } 
      return "default";
    
  } 
    throw new Error("Invalid amount format");
  
}

interface ChangedBy {
  name: string;
  nickname: string;
  avatar: string;
  requesterId: string;
}
interface Employee {
  id: number;
  date: string;
  amount: string;
  changedBy: string | ChangedBy;
  balance: number;
  comment: string;
}

export const tableRows = sampleEmployees.map(
  (employee: Employee, index: number) => ({
    key: employee.date,
    isHighlighted: false,
    cells: [
      {
        key: employee.id,
        content: employee.date,
      },
      {
        key: employee.id,
        content: (
          <StyledAmount balance={determineAmountType(employee.amount)}>
            {employee.amount}
          </StyledAmount>
        ),
      },
      {
        key: employee.id,
        content: (
          <Inline xcss={UserBlock}>
            {typeof employee.changedBy === "string" ? (
              <span>{employee.changedBy}</span>
            ) : (
              <>
                <Avatar
                  name={employee.changedBy.name}
                  src={employee.changedBy.avatar}
                  size="small"
                  appearance="circle"
                />
                <StyledUserBlockInfo>
                  <Link to={`/employee/${  employee.changedBy.requesterId}`}>
                    <Box
                      xcss={xcss({
                        width: "160px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        textWrap: "nowrap",
                      })}
                    >
                      {employee.changedBy.name}
                    </Box>
                  </Link>
                  <span>{employee.changedBy.nickname}</span>
                </StyledUserBlockInfo>
              </>
            )}
          </Inline>
        ),
      },
      {
        key: employee.id,
        content: employee.balance,
      },
      {
        key: employee.id,
        content: employee.comment,
      },
    ],
  })
);
