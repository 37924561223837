import { GoogleOAuthProvider } from '@react-oauth/google'
import { ModalProvider } from './application/Common/Context/ModalContext'
import { EmployeeContextProvider } from './contexts/EmployeeContext'
import { App } from './application/App'
import { Provider } from 'react-redux'
import { setupStore } from './store'

const store = setupStore()

export const ProvidersLoader = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}>
    <ModalProvider>
      <EmployeeContextProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </EmployeeContextProvider>
    </ModalProvider>
  </GoogleOAuthProvider>
)
