// TODO: fix eslint errors
/* eslint-disable */
import { type FC, createContext, useContext, useState, useEffect } from 'react'
import { type ProfileContextProps, type ProviderProps, SetReHireProps } from './ProfileContext.types'
import type { JobInfoProps } from '../../../API/profile/jobInfo/jobInfo.types'
import type { EmergencyContactProps } from '../../../API/profile/emergencyContact/emergencyContact.types'
import { TransformJobTitles } from '../../../API/profile/fields/jobInfo/jobTitles'
import type { ProfileResponseProps } from '../../../API/profile'
import { useEvent } from 'hooks/useEvent'
import type { TimeOffBalanceProps } from '../../../API/profile/timeOffBalance/timeOffBalance.types'

const ProfileContext = createContext<ProfileContextProps | null>(null)

export const ProfileContextProvider: FC<ProviderProps> = ({ value, children }) => {
  const [profile, setProfile] = useState<ProfileResponseProps>(value.profile)
  const [jobInfo, setJobInfo] = useState<JobInfoProps>()
  const [contactInfo, setContactInfo] = useState(value.contactInfo)
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContactProps>([])
  const [currentJobTitle, setCurrentJobTitle] = useState<TransformJobTitles>()

  const [icon, setIcon] = useState<string | undefined>()
  const [createRequestModal, setCreateRequestModal] = useState<boolean>(false)
  const [editAccruedDaysModal, setEditAccruedDaysModal] = useState<boolean>(false)

  const [timeOffBalance, setTimeOffBalance] = useState<Array<TimeOffBalanceProps>>()
  const [timeOffBalanceOne, setTimeOffBalanceOne] = useState<TimeOffBalanceProps>()
  const [updateTimeOffBalance, setUpdateTimeOffBalance] = useState(0)

  const setDirectManager = useEvent((directManagerId: string) => {
    setJobInfo({
      ...jobInfo!,
      currentJobInfoValue: {
        ...jobInfo!.currentJobInfoValue,
        directManagerId,
      },
    })
  })

  const setReHire = useEvent(({ hire_date = undefined, termination_date = undefined }: SetReHireProps) => {
    setJobInfo({
      ...jobInfo!,
      currentJobInfoValue: {
        ...jobInfo!.currentJobInfoValue,
        dates: {
          ...jobInfo!.currentJobInfoValue.dates,
          ...(typeof hire_date !== 'undefined' && { hireDate: hire_date }),
          ...(typeof termination_date !== 'undefined' && { terminationDate: termination_date }),
        },
      },
    })
  })

  const setFirstNameEn = useEvent((firstNameEN: string) => {
    setProfile({
      ...profile,
      firstNameEn: firstNameEN,
    })
  })

  const setLastNameEn = useEvent((lastNameEN: string) => {
    setProfile({
      ...profile,
      lastNameEn: lastNameEN,
    })
  })

  const setNickname = useEvent((nickname: string) => {
    setProfile({
      ...profile,
      nickname,
    })
  })

  const setCountry = useEvent((country: string) => {
    setProfile({
      ...profile,
      country,
    })
  })

  const setMainEmail = useEvent((mainEmail: string) => {
    setProfile({
      ...profile,
      email: mainEmail,
    })
  })

  useEffect(() => {
    setContactInfo(value.contactInfo)
  }, [value.contactInfo])

  useEffect(() => {
    setJobInfo(value.jobInfo)
  }, [value.jobInfo])

  useEffect(() => {
    setCurrentJobTitle(value.currentJobTitle)
  }, [value.currentJobTitle])

  return (
    <ProfileContext.Provider
      value={{
        isActiveProfile: value.isActiveProfile,
        idOpenProfile: value.idOpenProfile,
        isAccessEdit: value.isAccessEdit,
        isAccessEditRole: value.isAccessEditRole,
        isManagerOpenProfile: value.isManagerOpenProfile,
        profile,
        jobInfo,
        emergencyContact,
        setEmergencyContact,
        currentJobTitle,
        setCurrentJobTitle,
        setDirectManager,
        setFirstNameEn,
        setLastNameEn,
        setNickname,
        setCountry,
        setMainEmail,
        contactInfo,
        companyName: value.companyName,
        setCompanyName: value.setCompanyName,
        reFetchContactInfo: value.reFetchContactInfo,

        createRequestModal,
        setCreateRequestModal,
        editAccruedDaysModal,
        setEditAccruedDaysModal,

        timeOffBalance,
        setTimeOffBalance,
        timeOffBalanceOne,
        setTimeOffBalanceOne,
        updateTimeOffBalance,
        setUpdateTimeOffBalance,
        icon,
        setIcon,

        setReHire,
        showErrorNotify: value.showErrorNotify,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext) as ProfileContextProps
