// TODO: fix eslint errors
/* eslint-disable */
import DynamicTable from '@atlaskit/dynamic-table'
import { Link } from 'react-router-dom'
import { useEffect, useState, type FC, type ReactNode } from 'react'
import DrawerWithSpoiler from 'components/UI/DrawerWithSpoiler'
import kebabCase from 'lodash/kebabCase'
import Avatar from '@atlaskit/avatar'
import { Box, xcss } from '@atlaskit/primitives'
import { INTERVIEWS } from '../../utils/constants/api'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import Button from '@atlaskit/button/new'
import Lozenge from '@atlaskit/lozenge'
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../../assets/images/ProfileIcons/Stars.svg'
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import { useEmployee } from '../../contexts/EmployeeContext'
import { useSubordinates } from '../../API/profile/subordinates'
import TeamAnalysisModal from './team-analysis-modal'
import { FilterCalendar, DayRange } from 'components/UI/FilterCalendarAI'
import { StyledTeamAnalysisPage } from './styles'
import { useFlags } from '@atlaskit/flag'
import SuccessIcon from '@atlaskit/icon/glyph/check-circle'

const DynamicTableWrapper: FC<{
  children: ReactNode
}> = styled.div`
  table {
    cursor: default !important;
  }
  th {
    color: ${token('color.text.subtle')};
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 590;
    line-height: 16px;
  }
  th:first-child {
    padding-left: ${token('space.150')};
  }

  tr td {
    cursor: default !important;
    height: 50px;
    padding: ${token('space.0')};
    padding-left: ${token('space.100')};
    padding-right: ${token('space.100')};
  }
`

const ReassignButtonWrapper: FC<{
  children: ReactNode
}> = styled.div`
  button {
    width: 216px;
  }
`

const AssignButtonWrapper: FC<{
  children: ReactNode
}> = styled.div`
  button {
    border-radius: 3px;
    border: 1px solid ${token('color.border')};
    background: ${token('color.background.accent.blue.subtlest')};
    span {
      display: flex;
      align-items: center;
    }
  }
`

const AdviceButtonWrapper: FC<{
  children: ReactNode
}> = styled.div`
  button {
    width: 216px;
    background-color: ${() => token('color.background.neutral')};
    color: ${token('color.text')};
    padding-left: ${token('space.150')};
    padding-right: ${token('space.150')};
    display: flex !important;
    justify-content: center;
    align-items: center;

    svg path {
      fill: ${token('color.text.discovery')};
    }
  }

  button span {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`

export const createHead = (withWidth: boolean) => ({
  cells: [
    {
      key: 'name',
      content: 'Employee',
      isSortable: true,
      width: withWidth ? 28 : undefined,
    },
    {
      key: 'date',
      content: 'Creation Date',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 21 : undefined,
    },
    {
      key: 'status',
      content: 'Status',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'assessment',
      content: 'Assessment and recommendations',
      shouldTruncate: true,
    },
  ],
})

function mapStatus(status: string) {
  // TODO: Fix bug
  // eslint-disable-next-line default-case
  switch (status) {
    case 'done':
      return 'success'
    case 'new':
      return 'inprogress'
    case 'processing':
      return 'moved'
    case 'failed':
      return 'removed'
  }
}

export const head = createHead(true)

type Option = {
  label: string
  value: string
  company?: string
}

export function TeamAnalysis() {
  const [reassignLoading, setReassignLoading] = useState(false)
  const [inited, setInited] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const { showFlag } = useFlags()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedEmployeeAdvice, setSelectedEmployeeAdvice] = useState<{
    [key: string]: string[]
  }>({})

  const [selectedEmployee, setSelectedEmployee] = useState<{
    [key: string]: any
  }>({})

  const { employee } = useEmployee()

  const { subordinates, fetchSubordinates } = useSubordinates(employee?.id as string)

  const [employeesOptions, setEmployeesOptions] = useState<Option[]>([])
  const [selectedEmployeesOptions, setSelectedEmployeesOptions] = useState<Option[]>([])

  const [advices, setAdvices] = useState<{ [key: string]: any }>({})
  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([])
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<Option[]>([])

  const [statusesOptions] = useState<Option[]>([
    {
      label: 'Done',
      value: 'done',
    },
    {
      label: 'New',
      value: 'new',
    },
    {
      label: 'Processing',
      value: 'processing',
    },
    { label: 'Failed', value: 'failed' },
  ])
  const [selectedStatusesOptions, setSelectedStatusesOptions] = useState<Option[]>([])

  const [dateRange, setDateRange] = useState<DayRange>({
    from: {
      day: new Date().getDate(),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    to: {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
  })

  const from = dateRange.from
    ? new Date(`${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`)
    : new Date()

  const to = dateRange.to ? new Date(`${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`) : new Date()

  useEffect(() => {
    if (subordinates?.length) {
      const subordinatesOptions = subordinates
        ? subordinates.map((item) => ({
            value: item.key,
            label: item.name,
            email: item.email,
            avatar: item.src,
          }))
        : []

      setEmployeesOptions(subordinatesOptions as Option[])

      const orgUnits: { label: string; value: string; company: string }[] = []

      subordinates.forEach((item) => {
        if (orgUnits.find((obj) => obj.value === item.orgUnitId)) {
          /* empty */
        } else {
          orgUnits.push({
            label: item.orgUnitName,
            value: item.orgUnitId,
            company: item.companyName,
          })
        }
      })

      setOrgUnitOptions(orgUnits)
    }
  }, [subordinates])

  function getAdvice() {
    fetch(`${INTERVIEWS}/advices-for-manager`)
      .then((res) => res.json())
      .then(
        (data: {
          [key: string]: {
            orgUnitName: string
            orgUnitId: string
          }
        }) => {
          setAdvices(data)
          setInited(true)
        },
      )
  }

  useEffect(() => {
    fetchSubordinates()
    getAdvice()
  }, [])

  const rows = Object.keys(advices)
    .filter(
      (key) =>
        selectedOrgUnitOptions.find((item) => item.value === advices[key].orgUnitId) ||
        selectedOrgUnitOptions.length === 0,
    )
    .filter(
      (key) =>
        selectedStatusesOptions.find((item) => item.value === advices[key].status) ||
        selectedStatusesOptions.length === 0,
    )
    .filter((key) => {
      if (from) {
        return (
          new Date(advices[key].date).getTime() >= from?.getTime() &&
          new Date(advices[key].date).getTime() <= to?.getTime()
        )
      }
      return true
    })
    .filter(
      (key) =>
        selectedEmployeesOptions.find((item) => item.value === advices[key].employeeId) ||
        selectedEmployeesOptions.length === 0,
    )
    .map((key) => ({
      key: kebabCase(advices[key].name),
      isHighlighted: false,
      cells: [
        {
          key: advices[key].name,
          content: (
            <Box xcss={xcss({ display: 'flex', alignItems: 'center' })}>
              <Box
                xcss={xcss({
                  marginLeft: 'space.100',
                  marginRight: 'space.100',
                })}
              >
                <Avatar src={advices[key].avatar} size="small" />
              </Box>

              <Box>
                <Box
                  xcss={xcss({
                    color: 'color.text',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                  })}
                >
                  <Link to={`/employee/${advices[key].employeeId}`} style={{ color: 'inherit' }}>
                    {advices[key].firstName} {advices[key].lastName}
                  </Link>
                </Box>

                <Box
                  xcss={xcss({
                    color: 'color.text.subtlest',
                    fontFamily: 'inherit',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '14px',
                  })}
                >
                  {advices[key].nickname}
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          key: advices[key].date,
          content: (
            <Box
              xcss={xcss({
                color: 'color.text.subtlest',
                fontFamily: 'inherit',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              })}
            >
              {new Date(advices[key].date).toLocaleDateString('uk-UA', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })}
            </Box>
          ),
        },
        {
          key: advices[key].status,
          content: <Lozenge appearance={mapStatus(advices[key].status)}>{advices[key].status}</Lozenge>,
        },
        {
          ...(advices[key].status === 'failed'
            ? {
                key: advices[key].id,
                content: advices[key].canOpenRequest ? (
                  <ReassignButtonWrapper>
                    <Button
                      isLoading={reassignLoading}
                      onClick={() => {
                        setReassignLoading(true)
                        fetch(`${INTERVIEWS}/request`, {
                          method: 'POST',
                          body: JSON.stringify([advices[key].employeeId]),
                        }).then(() => {
                          setReassignLoading(false)
                          getAdvice()
                          showFlag({
                            icon: <SuccessIcon label="Success" primaryColor={token('color.background.success.bold')} />,
                            title: `You've successfully reassign analysis.`,
                            isAutoDismiss: true,
                          })
                        })
                      }}
                      appearance="primary"
                    >
                      Reassign analysis
                    </Button>
                  </ReassignButtonWrapper>
                ) : null,
              }
            : {
                key: advices[key].id,
                content:
                  Object.keys(advices[key].advice).length > 0 ? (
                    <AdviceButtonWrapper>
                      <Button
                        onClick={() => {
                          setOpenDrawer(true)
                          setSelectedEmployeeAdvice(advices[key].advice)
                          setSelectedEmployee(advices[key])
                        }}
                      >
                        <Stars style={{ marginRight: '4px' }} />
                        Show recommendations
                      </Button>
                    </AdviceButtonWrapper>
                  ) : null,
              }),
        },
      ],
    }))

  return (
    <StyledTeamAnalysisPage>
      <Box>
        <h1
          style={{
            color: token('color.text'),
            fontFamily: 'inherit',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
          }}
        >
          AI personality analysis
        </h1>
      </Box>
      <Box
        xcss={xcss({
          marginTop: 'space.100',
        })}
      >
        <FilterCalendar
          setDateRange={setDateRange}
          defaultRange={{
            range: dateRange,
            name: 'This Month',
          }}
        />
      </Box>
      <Box
        xcss={xcss({
          marginTop: 'space.100',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Box
          xcss={xcss({
            display: 'flex',
            gap: 'space.200',
          })}
        >
          {orgUnitOptions.length > 1 && (
            <MultiSelectCustom
              onSelectClick={() => {}}
              hasSelectAll={false}
              placeholder="Org Unit"
              labelledBy="All Org Units"
              options={orgUnitOptions}
              onChange={(values) => {
                setSelectedOrgUnitOptions(values as Option[])
              }}
              value={selectedOrgUnitOptions}
            />
          )}
          {employeesOptions.length > 1 && (
            <MultiSelectCustom
              contentWidth={300}
              onSelectClick={() => {}}
              hasSelectAll={false}
              placeholder="Employee"
              labelledBy="Employee"
              options={employeesOptions}
              onChange={(values) => {
                setSelectedEmployeesOptions(values as Option[])
              }}
              value={selectedEmployeesOptions}
            />
          )}
          <MultiSelectCustom
            hideSearch
            onSelectClick={() => {}}
            hasSelectAll={false}
            placeholder="Status"
            labelledBy="Status"
            options={statusesOptions}
            onChange={(values) => {
              setSelectedStatusesOptions(values as Option[])
            }}
            value={selectedStatusesOptions}
          />
        </Box>

        <Box>
          <AssignButtonWrapper>
            <Button
              onClick={() => {
                setModalOpen((val) => !val)
              }}
            >
              <EditorAddIcon label="add" />
              Assign analysis
            </Button>
          </AssignButtonWrapper>
        </Box>
      </Box>
      <Box xcss={xcss({ marginTop: 'space.300', paddingTop: 'space.050' })}>
        {/* TODO: fix eslint */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {inited ? (
          rows.length ? (
            <DynamicTableWrapper>
              <DynamicTable
                head={head}
                rows={rows}
                rowsPerPage={10}
                defaultPage={1}
                loadingSpinnerSize="large"
                isRankable
              />
            </DynamicTableWrapper>
          ) : (
            <Box
              xcss={xcss({
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              })}
            >
              <img src="/image/NoResultsFound.svg" style={{ width: '324px' }} alt="img" />
              <h3
                style={{
                  marginTop: '0',
                  color: token('color.text'),
                  fontFamily: 'inherit',
                  fontSize: '35px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '40px',
                }}
              >
                There is no information here yet
              </h3>
              <Box xcss={xcss({ marginTop: 'space.200' })}>
                <AssignButtonWrapper>
                  <Button
                    onClick={() => {
                      setModalOpen((val) => !val)
                    }}
                  >
                    <EditorAddIcon label="add" />
                    Assign analysis
                  </Button>
                </AssignButtonWrapper>
              </Box>
            </Box>
          )
        ) : null}
      </Box>
      {/* TODO: fix eslint */}
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <TeamAnalysisModal isOpen={modalOpen} setIsOpen={setModalOpen} onConfirm={getAdvice} />
      <DrawerWithSpoiler
        open={openDrawer}
        setOpen={setOpenDrawer}
        advice={selectedEmployeeAdvice}
        title="Оцінка та рекомендації"
        subTitle={
          <Box
            xcss={xcss({
              display: 'flex',
              alignItems: 'center',
              paddingTop: 'space.150',
              paddingBottom: 'space.150',
            })}
          >
            <Box
              xcss={xcss({
                paddingLeft: 'space.100',
                paddingRight: 'space.100',
              })}
            >
              <Avatar src={selectedEmployee.avatar} size="small" />{' '}
            </Box>
            <Box
              xcss={xcss({
                color: 'color.text',
                fontFamily: 'inherit',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              })}
            >
              {selectedEmployee.firstName} {selectedEmployee.lastName}
            </Box>
          </Box>
        }
      />
    </StyledTeamAnalysisPage>
  )
}
