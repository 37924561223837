// TODO: fix eslint errors
/* eslint-disable */
import { FC } from 'react'
import cn from 'classnames'
import style from './User.module.scss'
import { IconButton } from '@atlaskit/button/new'
import SignInIcon from '@atlaskit/icon/glyph/sign-in'
import { Tooltip } from '../../../UI/Tooltip'
import { useEmployee, assertEmployee } from '../../../../contexts/EmployeeContext'
import { useSignOut } from '../../../../API/auth/useSignOut'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProfileAvatar } from '../../../Profile/ProfileAvatar'

export const User: FC = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)
  const navigate = useNavigate()
  const location = useLocation()
  const { signOut } = useSignOut()

  const profileLink = `/employee/${employee.id}`
  const isInProfile = location.pathname === profileLink

  const toProfile = () => {
    navigate(profileLink)
  }

  return (
    <div className={style.user}>
      <Tooltip position="bottom" delay={0} content="Your profile and settings">
        {(tooltipProps) => (
          <a
            {...tooltipProps}
            onClick={(event) => {
              event.preventDefault()
              toProfile()
            }}
            href={profileLink}
            className={cn(style.avatarWrapper)}
          >
            <ProfileAvatar
              size={32}
              imgAvatar={employee.imageUrl}
              firstName={employee.firstNameEn}
              lastName={employee.lastNameEn}
            />
          </a>
        )}
      </Tooltip>
      <div className={cn(style.signOut, style.signOutBtn)}>
        <Tooltip position="bottom" delay={0} content="SignOut">
          {(tooltipProps) => (
            <IconButton
              {...tooltipProps}
              appearance="subtle"
              shape="circle"
              icon={SignInIcon}
              label="SignOut"
              onClick={signOut}
            />
          )}
        </Tooltip>
      </div>
    </div>
  )
}
