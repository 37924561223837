// TODO: fix eslint errors
/* eslint-disable */
import { FC, useState } from 'react'
import BaseModal from './BaseModal'
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { EMPLOYEES } from '../../../../../../../utils/constants/api'
import { VALIDATION_ERROR } from '../../../../../../../utils/constants/errors'
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError'
import { assertEmployee, useEmployee } from '../../../../../../../contexts/EmployeeContext'
import SuccessIcon from '@atlaskit/icon/glyph/check-circle'
import { useFlags } from '@atlaskit/flag'
import { token } from '@atlaskit/tokens'

interface CreateEmployeeProps {
  opened: boolean
  onOk: () => void
  onClose: () => any
}

export const CreateEmployee: FC<CreateEmployeeProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const { showFlag } = useFlags()
  const { employee } = useEmployee()
  assertEmployee(employee)

  const create = (
    nickname: string | null,
    lastNameEn: string,
    firstNameEn: string,
    email: string,
    validate: (errors: ValidationError[]) => void,
  ): void => {
    setLoading(true)

    const formData = new FormData()
    formData.append(
      'employee',
      JSON.stringify({
        nickname,
        lastNameEn,
        firstNameEn,
        email,
      }),
    )

    fetch(EMPLOYEES, { method: 'POST', body: formData })
      .then((response) => {
        console.log({ response })

        if (response.ok) {
          response.json().then((id) => {
            showFlag({
              actions: [
                {
                  content: 'Go to employee profile',
                  href: `${EMPLOYEE_PROFILE}/${id}`,
                },
              ],
              description: `You can go to the employee's profile to fill in additional information. Until you set the working status in the profile, the employee will remain in the inactive employees section`,
              icon: <SuccessIcon primaryColor={token('color.icon.success')} label="Success" />,
              title: `You’ve added new employee to the system`,
              isAutoDismiss: true,
            })
            props.onOk()
          })
        } else if (response.status === VALIDATION_ERROR) {
          response.json().then((errors: ValidationError[]) => validate(errors))
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <BaseModal
        opened={props.opened}
        modalTitle="Create Employee"
        okText="Create"
        onCancel={props.onClose}
        onOk={create}
        loading={loading}
      />
    </>
  )
}
