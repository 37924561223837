// TODO: Create svg sprite
// https://www.figma.com/design/Uyc0uL6J6VBO1uAbXhTPtH/Directory?node-id=1-21142&m=dev

import {EmptyTableStyles} from './styles'
import {IEmptyTableTemplateProps} from './types'

export const EmptyTableTemplate = ({children}: IEmptyTableTemplateProps) => (
  <EmptyTableStyles>
    {children}
  </EmptyTableStyles>
)
