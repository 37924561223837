// TODO: fix eslint errors
/* eslint-disable */
import { FC, useState, useCallback, useEffect, useMemo } from 'react'
import { Box, Stack, Inline, xcss } from '@atlaskit/primitives'
import AddIcon from '@atlaskit/icon/glyph/add'
import { RequestTimeOffModal } from 'components'
import { FilterCalendar, defaultRangeAttendanceLog, DayRange } from 'components/UI/FilterCalendar'
import Button from '@atlaskit/button/new'
import PolicySelect from './PolicySelect/PolicySelect'
import DynamicTable from '@atlaskit/dynamic-table'
import { tableHeader } from './RequestsTable/TableHeader'
import { tableRow } from './RequestsTable/TableRow'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import { StyledMainTitle, StyledRequestPage, StyledRequestButton, StyledTableWrap } from './styles'
import { assertEmployee, useEmployee } from 'contexts/EmployeeContext'
import { CALENDAR, EMPLOYEES, FILTERS } from 'utils/constants/api'
import { ROLE_HR } from 'shared/Security/constants/AccessLevels'
import { isOverlap } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'
import { useSearchParams } from 'react-router-dom'
import { TRequestData, SelectOptions } from './types'
import { TSubordinates } from 'types/subordinates'
import { NoResults, NoData } from 'components/UI/table'
import { SkeletonTable } from 'components/UI/skeleton-table'

const statusesOptions = [
  {
    value: 1,
    label: 'New',
  },
  {
    value: 2,
    label: 'Approved',
  },
  {
    value: 3,
    label: 'Rejected',
  },
  {
    value: 4,
    label: 'Cancelled',
  },
]

export const Requests: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = (filters: any[]) => {
    const searchParams = new URLSearchParams(window.location.search)
    filters.forEach((item) => {
      if (item.value.length > 0) {
        searchParams.set(item.name, item.value)
      } else {
        searchParams.delete(item.name)
      }
    })
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const { employee, getRequests } = useEmployee()
  assertEmployee(employee)

  function validDate(date: string) {
    if (!isNaN(new Date(date).valueOf())) {
      return new Date(date)
    }

    return new Date()
  }

  const [dateRange, setDateRange] = useState<DayRange>({
    from: {
      day: searchParams.get('datefrom') ? validDate(searchParams.get('datefrom') as string).getDate() : 1,
      month: searchParams.get('datefrom')
        ? validDate(searchParams.get('datefrom') as string).getMonth() + 1
        : new Date().getMonth() + 1,
      year: searchParams.get('datefrom')
        ? validDate(searchParams.get('datefrom') as string).getFullYear()
        : new Date().getFullYear(),
    },
    to: {
      day: searchParams.get('dateto') ? validDate(searchParams.get('dateto') as string).getDate() : 1,
      month: searchParams.get('dateto')
        ? validDate(searchParams.get('dateto') as string).getMonth() + 1
        : new Date().getMonth() + 2,
      year: searchParams.get('dateto')
        ? validDate(searchParams.get('dateto') as string).getFullYear()
        : new Date().getFullYear(),
    },
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [showTable, setShowTable] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [])
  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const [subordinates, setSubordinates] = useState<TSubordinates[]>([])

  const [requestsListData, setRequestsListData] = useState<TRequestData[]>([])

  const [orgUnitsOptions, setOrgUnitsOptions] = useState<SelectOptions[]>([])
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<SelectOptions[]>([])
  const [employeesOptions, setEmployeesOptions] = useState<SelectOptions[]>([])
  const [selectedEmployeesOptions, setSelectedEmployeesOptions] = useState<SelectOptions[]>([])

  const [selectedStatuses, setSelectedStatuses] = useState<{ value: any; label: string }[]>([])
  const [policiesOptions, setPoliciesOptions] = useState<SelectOptions[]>([])
  const [selectedPolicies, setSelectedPolicies] = useState<{ value: any; label: string }[]>([])

  let roleHR: boolean = false
  if (employee) {
    roleHR = employee.roles.includes(ROLE_HR)
  }
  const isManager = subordinates.length > 0

  const onPageChange = (page: any) => {
    setCurrentPage(page)
  }

  const handleCreateNewClick = () => {
    openModal()
  }

  const fetchRequests = () => {
    const requestBody = {
      'requests-list': {
        employeeIds: [],
        section: 'requests',
        statuses: '',
        startDate: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
        endDate: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
      },
    }

    fetch(`${CALENDAR}/requests-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((newRequests) => {
        setShowTable(true)

        setRequestsListData(newRequests)
        getRequests()
      })
  }

  useEffect(() => {
    fetchRequests()
  }, [dateRange.to])

  useEffect(() => {
    fetch(`${FILTERS}/policies-by-employee`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const policies = data.map((item: { icon: string; policyId: string; policyName: string }) => ({
            label: item.policyName,
            value: item.policyId,
            icon: item.icon,
          }))
          setPoliciesOptions(policies)
        }
      })

    fetch(`${EMPLOYEES}/employee/${employee.id}/subordinate-direct-employees`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSubordinates(data)

        if (data.length > 0 && !roleHR) {
          const orgUnits = data
            .map((item: any) => ({
              label: item.orgUnitName,
              value: item.orgUnitId,
              company: item.companyName,
            }))
            .filter(
              (item: any, index: number, self: any) => index === self.findIndex((t: any) => t.label === item.label),
            )

          setOrgUnitsOptions(orgUnits)

          const employees = data.map(
            (item: {
              firstNameEn: string
              lastNameEn: string
              id: string
              email: string
              image: string
              nickname: string
            }) => ({
              label: `${item.firstNameEn} ${item.lastNameEn}${item.nickname ? ` (${item.nickname})` : ''}`,
              value: item.id,
              email: item.email,
              avatar: item.image,
            }),
          )

          employees.push({
            label: `${employee.firstNameEn} ${
              employee.lastNameEn
            }${employee.nickname ? ` (${employee.nickname})` : ''}`,
            value: employee.id,
            email: employee.email,
            avatar: employee.imageUrl,
          })

          setEmployeesOptions(employees)
        }
      })

    if (roleHR) {
      fetch(`${FILTERS}/calendar/org-units-by-employees`)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          return {}
        })
        .then((data) => {
          if ('orgUnits' in data) {
            const orgUnits = Object.values(
              (data as { orgUnits: any }).orgUnits as {
                name: string
                id: string
                companyName: string
              }[],
            ).map((item) => ({
              label: item.name,
              value: item.id,
              company: item.companyName,
            }))
            setOrgUnitsOptions(orgUnits)
          }
        })
    }
    if (roleHR) {
      fetch(`${EMPLOYEES}/type/active`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const employees = data.map(
              (item: {
                firstNameEn: string
                lastNameEn: string
                id: string
                email: string
                avatar: string
                nickname: string
              }) => ({
                label: `${item.firstNameEn} ${item.lastNameEn}${item.nickname ? ` (${item.nickname})` : ''}`,
                value: item.id,
                email: item.email,
                avatar: item.avatar,
              }),
            )
            setEmployeesOptions(employees)
          }
        })
    }
  }, [])

  useEffect(() => {
    const selectedStatusesOptions: SelectOptions[] = []
    if (searchParams.get('statuses')) {
      searchParams
        .get('statuses')
        ?.split(',')
        .forEach((item) => {
          const elm = statusesOptions.find((status: SelectOptions) => status.label.toLowerCase() === item.toLowerCase())
          if (elm) {
            selectedStatusesOptions.push(elm)
          }
        })
    }

    setSelectedStatuses(selectedStatusesOptions)

    const selectedPolicies: SelectOptions[] = []

    if (searchParams.get('policies')) {
      searchParams
        .get('policies')
        ?.split(',')
        .forEach((item) => {
          const elm = policiesOptions.find((policy: SelectOptions) => policy.value === item)
          if (elm) {
            selectedPolicies.push(elm)
          }
        })
    }

    setSelectedPolicies(selectedPolicies)

    const selectedOrgUnits: SelectOptions[] = []

    if (searchParams.get('orgunits')) {
      searchParams
        .get('orgunits')
        ?.split(',')
        .forEach((item) => {
          const elm = orgUnitsOptions.find((orgunit: SelectOptions) => orgunit.value === item)
          if (elm) {
            selectedOrgUnits.push(elm)
          }
        })
    }

    setSelectedOrgUnitOptions(selectedOrgUnits)

    const selectedEmployees: SelectOptions[] = []

    if (searchParams.get('employees')) {
      searchParams
        .get('employees')
        ?.split(',')
        .forEach((item) => {
          const elm = employeesOptions.find((orgunit: SelectOptions) => orgunit.value === item)
          if (elm) {
            selectedEmployees.push(elm)
          }
        })
    }

    setSelectedEmployeesOptions(selectedEmployees)
  }, [policiesOptions, orgUnitsOptions, employeesOptions])

  useEffect(() => {
    updateQueryParams([
      {
        name: 'statuses',
        value: selectedStatuses.map((item) => item.label).join(','),
      },
      {
        name: 'policies',
        value: selectedPolicies.map((item) => item.value).join(','),
      },
      {
        name: 'orgunits',
        value: selectedOrgUnitOptions.map((item) => item.value).join(','),
      },
      {
        name: 'employees',
        value: selectedEmployeesOptions.map((item) => item.value).join(','),
      },
      {
        name: 'datefrom',
        value: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
      },
      {
        name: 'dateto',
        value: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
      },
    ])
  }, [
    selectedStatuses,
    selectedPolicies,
    selectedOrgUnitOptions,
    selectedEmployeesOptions,
    dateRange.to,
    dateRange.from,
  ])

  const requestsListDataFiltered = requestsListData
    .filter((item) =>
      isOverlap(
        new Date(item.startDate),
        new Date(item.endDate),
        new Date(`${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`),
        new Date(`${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`),
      ),
    )
    .filter(
      (item) =>
        selectedStatuses.find((status) => status.label.toLocaleLowerCase() === item.status.toLocaleLowerCase()) ||
        selectedStatuses.length === 0,
    )
    .filter(
      (item) =>
        selectedPolicies.find((policy) =>
          item.policyId ? policy.value.toLocaleLowerCase() === item.policyId.toLocaleLowerCase() : false,
        ) || selectedPolicies.length === 0,
    )
    .filter(
      roleHR || isManager
        ? (item) =>
            selectedOrgUnitOptions.find((unit) =>
              item.requesterOrgUnitId
                ? unit.value.toLocaleLowerCase() === item.requesterOrgUnitId.toLocaleLowerCase()
                : false,
            ) || selectedOrgUnitOptions.length === 0
        : () => true,
    )
    .filter(
      roleHR || isManager
        ? (item) =>
            selectedEmployeesOptions.find((employee) =>
              item.requesterId ? employee.value.toLocaleLowerCase() === item.requesterId.toLocaleLowerCase() : false,
            ) || selectedEmployeesOptions.length === 0
        : () => true,
    )

  const tableRows = tableRow(
    requestsListDataFiltered.map((item: TRequestData) => ({
      id: item.id,
      requesterId: item.requesterId,
      name: item.requesterName,
      nickname: item.requesterNickname,
      img: item.requesterAvatar,
      date: item.createDate,
      policy: item.policyName,
      policyImg: item.typeIcon,
      timeStart: item.startDate,
      timeEnd: item.endDate,
      substitute: item.substituteName,
      substituteId: item.substituteId,
      substituteNickname: item.substituteNickname,
      substituteImg: item.substituteAvatar,
      status: item.status.toLocaleLowerCase(),
      statusComment: item.comment,
      statusChangeByName: item.statusChangeByName,
      statusChangeBy: item.statusChangeBy,
      createdBy: item.createdBy,
      createdByName: item.createdByName,
      lastDateSalary: item.lastDateSalary,
      days: item.days,
    })),
    employee,
    subordinates,
    () => {
      fetchRequests()
    },
  )

  const CustomDynamicTable = useMemo(
    () => (
      <DynamicTable
        head={tableHeader}
        rows={tableRows}
        rowsPerPage={12}
        defaultPage={1}
        loadingSpinnerSize="large"
        page={currentPage}
        onSetPage={onPageChange}
      />
    ),
    [currentPage, tableHeader, tableRows],
  )
  const OrgUnitSelect = useMemo(
    () => (
      <MultiSelectCustom
        selectWidth={160}
        hasSelectAll={false}
        options={orgUnitsOptions}
        onChange={(values) => {
          setSelectedOrgUnitOptions(values)
        }}
        value={selectedOrgUnitOptions}
        placeholder="Org Unit"
        labelledBy="All Org Units"
      />
    ),
    [orgUnitsOptions, selectedOrgUnitOptions],
  )
  const EmployeeSelect = useMemo(
    () => (
      <MultiSelectCustom
        contentWidth={300}
        dropdownHorizontalDisplacement="0"
        hasSelectAll={false}
        options={employeesOptions}
        onChange={(values) => {
          setSelectedEmployeesOptions(values)
        }}
        value={selectedEmployeesOptions}
        placeholder="Employees"
        labelledBy="All Employees"
      />
    ),
    [employeesOptions, selectedEmployeesOptions],
  )

  return (
    <StyledRequestPage>
      {!showTable && <SkeletonTable isCalendar filtersCount={4} colCount={7} rowCount={12} />}
      <Box
        xcss={xcss({
          paddingBottom: 'space.200',
        })}
      >
        <Stack space="space.100">
          <Inline>
            <StyledMainTitle>Requests: Time off and special work statuses</StyledMainTitle>
          </Inline>
          <Inline>
            <FilterCalendar
              setDateRange={setDateRange}
              defaultRange={{
                range: dateRange,
                name: defaultRangeAttendanceLog.name,
              }}
            />
          </Inline>
          <Inline space="space.200">
            <MultiSelectCustom
              selectWidth={160}
              hasSelectAll={false}
              options={statusesOptions}
              onChange={(values) => {
                setSelectedStatuses(values)
              }}
              value={selectedStatuses}
              placeholder="Status"
              labelledBy="Status select"
              hideSearch
            />

            <PolicySelect
              policiesOptions={policiesOptions}
              placeholder="Policy"
              labelledBy="Policy select"
              selected={selectedPolicies}
              setSelected={setSelectedPolicies}
            />

            {roleHR || isManager ? (
              <>
                {employeesOptions.length > 1 || roleHR ? OrgUnitSelect : null}

                {EmployeeSelect}
              </>
            ) : null}

            <StyledRequestButton selected={isModalOpen}>
              <Button iconBefore={(iconProps) => <AddIcon {...iconProps} size="small" />} onClick={() => openModal()}>
                Request Time Off
              </Button>
            </StyledRequestButton>
          </Inline>
        </Stack>
      </Box>

      {requestsListData.length > 0 ? (
        requestsListData.length > 0 && requestsListDataFiltered.length === 0 ? (
          <NoResults />
        ) : (
          <StyledTableWrap>{CustomDynamicTable}</StyledTableWrap>
        )
      ) : (
        <NoData title="There are no requests here yet" onClick={handleCreateNewClick} btnText="Request Time Off" />
      )}

      {isModalOpen && (
        <RequestTimeOffModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isManager={isManager}
          isHR={roleHR}
          viewer={employee}
          subordinates={subordinates}
          updateData={() => {
            fetchRequests()
          }}
        />
      )}
    </StyledRequestPage>
  )
}
