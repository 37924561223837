// TODO: fix eslint errors
/* eslint-disable */
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import { Box, xcss } from '@atlaskit/primitives'
import { ReactNode, FC, useState, useEffect } from 'react'
import AIButton from '../../../Profile/ShortInformationCard/AIButton'
import AIResultsButton from '../../../Profile/ShortInformationCard/AIResultsButton'
import { useNavigate } from 'react-router-dom'
import { type EmployeeType } from '../../../../contexts/EmployeeContext/EmployeeContext.types'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { TDirectManager } from './AIStatusButton'
import ManagerLinkButton from './ManagerLinkButton'

const Dot: FC<{
  $isActive?: boolean
}> = styled.div`
  border-radius: 100px;
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.$isActive ? token('color.icon.brand') : token('color.icon.subtle'))};
`

export default function ModalContentWithAIButton({
  employee,
  AIOpen,
  interviewsID,
  setAIOpen,
  lastInterview,
  directManager,
}: {
  employee: EmployeeType
  AIOpen: boolean
  interviewsID: string
  setAIOpen: (val: any) => void
  lastInterview: { id: string; status: string }
  directManager: TDirectManager | null
}) {
  const navigate = useNavigate()
  return (
    <Box>
      {lastInterview.status === 'new' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '22px',
            gap: '92px',
          }}
        >
          <Dot $isActive />
          <Dot $isActive={false} />
          <Dot $isActive={false} />
          <Dot $isActive={false} />
        </div>
      )}

      {lastInterview.status === 'processing' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '22px',
            gap: '92px',
          }}
        >
          <div
            style={{
              borderRadius: '50px',
              height: '8px',
              width: '208px',
              background: token('color.background.brand.bold'),
            }}
          />
          <Dot $isActive={false} />
        </div>
      )}

      {lastInterview.status === 'failed' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '22px',
            gap: '92px',
          }}
        >
          <div
            style={{
              borderRadius: '50px',
              height: '8px',
              width: '308px',
              background: `linear-gradient(90deg, ${token('color.background.brand.bold')} 80%, ${token('color.border.danger')} 20%)`,
            }}
          />
        </div>
      )}

      {lastInterview.status === 'done' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '22px',
            gap: '92px',
          }}
        >
          <div
            style={{
              borderRadius: '50px',
              height: '8px',
              width: '308px',
              background: token('color.background.brand.bold'),
            }}
          />
        </div>
      )}

      <Box
        xcss={xcss({
          display: 'flex',
          alignItems: 'center',
          marginTop: 'space.150',
        })}
      >
        <div>Received</div>
        <div
          style={{
            paddingLeft: '36px',
          }}
        >
          Taking test
        </div>
        <div
          style={{
            paddingLeft: '36px',
          }}
        >
          Processing
        </div>
        <div
          style={{
            paddingLeft: '50px',
          }}
        >
          Ready
        </div>
      </Box>
      {lastInterview.status === 'new' && (
        <Box
          xcss={xcss({
            marginTop: 'space.300',
          })}
        >
          {interviewsID && (
            <AIButton
              width={352}
              isActive={AIOpen}
              onClick={(e) => {
                setAIOpen((val: boolean) => !val)
              }}
            />
          )}
        </Box>
      )}
      {lastInterview.status === 'done' && (
        <Box
          xcss={xcss({
            marginTop: 'space.300',
          })}
        >
          <AIResultsButton
            width={352}
            onClick={(e) => {
              setAIOpen((val: boolean) => false)
              navigate(`/employee/${employee?.id}#personality-analysis-by-ai`)
            }}
          />
        </Box>
      )}

      {lastInterview.status === 'failed' && (
        <Box
          xcss={xcss({
            marginTop: 'space.300',
          })}
        >
          <Box
            xcss={xcss({
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <ErrorIcon primaryColor={token('color.icon.danger')} label="error" />
            <Box
              xcss={xcss({
                marginLeft: 'space.050',
                color: 'color.text',

                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              })}
            >
              Something went wrong
            </Box>
          </Box>
          <Box
            xcss={xcss({
              paddingTop: 'space.100',
              color: 'color.text.subtle',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              width: '376px',
            })}
          >
            {directManager?.id
              ? "It looks like the AI personality analysis didn't go as planned. No worries, though! Your manager can request a new analysis at any time."
              : "It looks like the AI personality analysis didn't go as planned. No worries, though! HR manager can request a new analysis at any time."}
          </Box>

          {directManager?.id && <ManagerLinkButton directManager={directManager} />}
        </Box>
      )}
    </Box>
  )
}
