import { FC, useEffect, useRef, useState } from "react";
import { NavProps } from "../Layout.types";
import { ReactComponent as LogoDesktop } from "../../../assets/images/logo/logo_core.svg";
import { ReactComponent as LogoMob } from "../../../assets/images/logo/logo_core_mob.svg";
import EditorSearchIcon from "@atlaskit/icon/glyph/editor/search";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { CheckInOut } from "./CheckInOut";
import { User } from "./User";
import MenuIcon from "@atlaskit/icon/glyph/menu";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { IconButton } from "@atlaskit/button/new";
import { Inline, xcss } from "@atlaskit/primitives";
import { useNavigate } from "react-router-dom";
import { MAIN } from "navigation";
import SearchBar from "components/Search/SearchBar/SearchBar";
import {
  StyledHeader,
  StyledHeaderMenuBtn,
  StyledHeaderSearch,
  StyledHeaderTime,
  StyledHeaderUser,
} from "./HeaderStyles";
import AIStatusButton from "./AIStatusButton/AIStatusButton";

export const Header: FC<NavProps> = ({ navIsOpen, navSwitchStatus }) => {
  const navigate = useNavigate();

  const toMain = () => {
    navigate(MAIN);
  };

  const input = useRef<HTMLInputElement>(null);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  useEffect(() => {
    searchIsOpen && input.current?.focus();
  });

  return (
    <StyledHeader $navIsOpen={navIsOpen}>
      <Inline alignBlock="center" grow="fill">
        <StyledHeaderMenuBtn onClick={navSwitchStatus}>
          <IconButton
            appearance="subtle"
            shape="circle"
            icon={navIsOpen ? CrossIcon : MenuIcon}
            label={navIsOpen ? "Close Menu" : "Open Menu"}
          />
        </StyledHeaderMenuBtn>

        <LogoDesktop className="logo" onClick={toMain} />
        <LogoMob className="logo--mob" onClick={toMain} />

        <StyledHeaderSearch $searchIsOpen={searchIsOpen}>
          <SearchBar $searchIsOpen={searchIsOpen} />

          <IconButton
            appearance="subtle"
            shape="circle"
            icon={searchIsOpen ? EditorCloseIcon : EditorSearchIcon}
            label="Search"
            onClick={() => {
              setSearchIsOpen(!searchIsOpen);
            }}
          />
        </StyledHeaderSearch>
      </Inline>

      <Inline
        alignBlock="center"
        xcss={xcss({
          flexShrink: "0",
        })}
      >
        <AIStatusButton />

        <StyledHeaderTime>
          <CheckInOut />
        </StyledHeaderTime>

        <StyledHeaderUser>
          <User />
        </StyledHeaderUser>
      </Inline>
    </StyledHeader>
  );
};
