// TODO: fix eslint errors
/* eslint-disable */
import Avatar from '@atlaskit/avatar'
import { Link } from 'react-router-dom'
import { Inline, Box, xcss } from '@atlaskit/primitives'
import Lozenge from '@atlaskit/lozenge'
import TableStatusPopup from './TableStatusPopup'
import LinkIcon from '@atlaskit/icon/glyph/link'
import Button, { IconButton } from '@atlaskit/button/new'

import Tooltip from '@atlaskit/tooltip'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import CheckIcon from '@atlaskit/icon/glyph/check'
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import Info from '@atlaskit/icon/glyph/info'
import { useFlags } from '@atlaskit/flag'
import { token } from '@atlaskit/tokens'
import { UserBlock, StyledUserBlockInfo, DateBlock, StyledStatusBlock, StyledActionsBlock } from './TableStyles'
import { formatDateToFriendlyShortTimeZone } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'

import { ROLE_HR } from 'HOC/AccessController/AccessController.constants'
import { LongTextTooltip } from 'components/UI/LongTextTooltip'

import { CALENDAR } from 'utils/constants/api'
import { getFormattedDate } from 'utils/helpers/formatted-date'

interface Employee {
  id: string
  requesterId: string
  name: string
  nickname: string
  img: string
  date: string
  policy: string
  policyImg: string
  timeStart: string
  timeEnd: string
  substitute: string
  substituteId: string
  substituteNickname: string
  substituteImg: string
  status: string
  statusComment: string
  statusChangeByName: string
  statusChangeBy: string
  createdBy: string
  createdByName: string
  lastDateSalary: string
  days: number
}

export const tableRow = (data: Employee[], currentEmployee: any, subordinates: any, updateData: () => void) =>
  data.map((employee: Employee, index: number) => ({
    key: `row-${employee.id}-${employee.name}`,
    cells: [
      {
        key: employee.name,
        content: (
          <Inline xcss={UserBlock}>
            <Avatar name={employee.name} src={employee.img} size="small" appearance="circle" />
            <StyledUserBlockInfo>
              <Link to={`/employee/${employee.requesterId}`}>
                <LongTextTooltip content={employee.name} maxContentLength={20}>
                  <Box
                    xcss={xcss({
                      width: '160px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                    })}
                  >
                    {employee.name}
                  </Box>
                </LongTextTooltip>
              </Link>
              <span>{employee.nickname}</span>
            </StyledUserBlockInfo>
          </Inline>
        ),
      },
      {
        key: employee.date,
        content: <Inline xcss={DateBlock}>{getFormattedDate(employee.date, 'coreHRDateFormat')}</Inline>,
      },
      {
        key: employee.policy,
        content: (
          <Tooltip content={employee.policy} delay={0} position="bottom">
            {(tooltipProps) => (
              <div style={{ display: 'inline-block' }} {...tooltipProps}>
                {TypeIconsCollection[employee.policyImg as keyof typeof TypeIconsCollection](
                  'large-icon',
                  'withouthover',
                )}
              </div>
            )}
          </Tooltip>
        ),
      },
      {
        key: employee.date,
        content: (
          <Inline xcss={DateBlock}>
            <span>{`${formatDateToFriendlyShortTimeZone(employee.timeStart)}, ${new Date(employee.timeStart).getFullYear()}`}</span>{' '}
            -{' '}
            <span>{`${formatDateToFriendlyShortTimeZone(employee.timeEnd)}, ${new Date(employee.timeEnd).getFullYear()}`}</span>{' '}
            ({employee.days} {employee.days === 1 ? 'day' : 'days'})
          </Inline>
        ),
      },
      {
        key: employee.substitute,
        content: employee.substituteId ? (
          <Inline xcss={UserBlock}>
            <Avatar name={employee.substitute} src={employee.substituteImg} size="small" appearance="circle" />
            <StyledUserBlockInfo>
              <Link to={`/employee/${employee.substituteId}`}>
                <LongTextTooltip content={employee.substitute} maxContentLength={20}>
                  <Box
                    xcss={xcss({
                      width: '160px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                    })}
                  >
                    {employee.substitute}
                  </Box>
                </LongTextTooltip>
              </Link>
              <span>{employee.substituteNickname}</span>
            </StyledUserBlockInfo>
          </Inline>
        ) : (
          <Inline xcss={UserBlock}>-</Inline>
        ),
      },
      {
        key: employee.status,
        content: (
          <StyledStatusBlock>
            <Lozenge style={getColors(employee.status)} appearance="default">
              {employee.status}
            </Lozenge>

            {(employee.statusComment || employee.createdByName || employee.statusChangeByName) && (
              <TableStatusPopup
                status={employee.status}
                comment={employee.statusComment}
                fullname={employee.status === 'new' ? employee.createdByName : employee.statusChangeByName}
                id={employee.statusChangeBy}
              />
            )}
          </StyledStatusBlock>
        ),
      },
      {
        key: `actions-${employee.id}`,
        content: (
          <StyledActionsBlock>
            <AllowedActions
              employee={employee}
              currentEmployee={currentEmployee}
              subordinates={subordinates}
              updateData={updateData}
            />
          </StyledActionsBlock>
        ),
      },
    ],
  }))

function getColors(status: string) {
  switch (status) {
    case 'new':
      return {
        color: token('color.text.information'),
        backgroundColor: token('color.background.information'),
      }
    case 'approved':
      return {
        color: token('color.text.success'),
        backgroundColor: token('color.background.success'),
      }
    case 'rejected':
      return {
        color: token('color.text.danger'),
        backgroundColor: token('color.background.danger'),
      }
    default:
      return {
        color: token('color.text.subtle'),
        backgroundColor: token('color.background.neutral'),
      }
  }
}

function AllowedActions({
  employee,
  currentEmployee,
  subordinates,
  updateData,
}: {
  employee: Employee
  currentEmployee: any
  subordinates: any
  updateData: () => void
}) {
  let roleHR = null
  if (currentEmployee) {
    roleHR = currentEmployee.roles.includes(ROLE_HR)
  }
  const directManager = subordinates.find((item: any) => item.id === employee.requesterId)

  const myRequest = employee.requesterId === currentEmployee.id

  const isClosed = employee.lastDateSalary
    ? new Date(employee.lastDateSalary).getTime() >= new Date(employee.timeStart).getTime() ||
      new Date(employee.lastDateSalary).getTime() >= new Date(employee.timeEnd).getTime()
    : false

  let result = ''
  const status = employee.status.toLocaleLowerCase()

  const { showFlag } = useFlags()

  if (isClosed) {
    result = 'inClosed'
  } else if (status === 'rejected' || status === 'cancelled') {
    result = '-'
  } else if (status === 'approved') {
    if (myRequest) {
      result = '-'
    } else if (directManager || roleHR) {
      result = 'Cancel'
    }
  } else if (status === 'new') {
    if (myRequest) {
      result = 'Cancel'
    } else if (directManager || roleHR) {
      result = 'Approved, Rejected'
    }
  }

  function changeStatus(requestId: string, status: string, employeeId: string) {
    fetch(`${CALENDAR}/requests/${requestId}/status/${status}?status-change-by=${employeeId}`, {
      method: 'PUT',
    }).then((response) => {
      updateData()
      showFlag({
        icon: <Info label="Info" primaryColor={token('color.icon.information')} />,
        title: `Request ${status.toLowerCase()}`,
        isAutoDismiss: true,
      })
    })
  }

  const copyLink = () => {
    copyToClipboard(
      `${
        window.location.host + window.location.pathname
      }?statuses=${employee.status}&employees=${employee.requesterId}&datefrom=${encodeURIComponent(employee.timeStart.substring(0, 10))}&dateto=${encodeURIComponent(employee.timeEnd.substring(0, 10))}`,
    )
  }

  const CopyButton = () => (
    <Tooltip content="Copy link" delay={0} position="bottom">
      {' '}
      <IconButton
        spacing="compact"
        icon={LinkIcon}
        label="Copy link"
        onClick={() => {
          copyLink()
          showFlag({
            icon: <Info label="Info" primaryColor={token('color.icon.information')} />,
            title: 'Link for request copied',
            isAutoDismiss: true,
          })
        }}
      />
    </Tooltip>
  )

  return result === '-' ? (
    <CopyButton />
  ) : result === 'Cancel' ? (
    <>
      {' '}
      <CopyButton />
      <Button
        onClick={() => {
          changeStatus(employee.id, 'cancelled', currentEmployee.id)
        }}
        aria-roledescription="cancel"
        spacing="compact"
      >
        Cancel
      </Button>
    </>
  ) : result === 'Approved, Rejected' ? (
    <>
      <CopyButton />
      <Tooltip content="Approve" delay={0} position="bottom">
        <IconButton
          onClick={() => {
            changeStatus(employee.id, 'approved', currentEmployee.id)
          }}
          appearance="primary"
          spacing="compact"
          icon={CheckIcon}
          label="Approve"
        />
      </Tooltip>
      <Tooltip content="Reject" delay={0} position="bottom">
        <IconButton
          onClick={() => {
            changeStatus(employee.id, 'rejected', currentEmployee.id)
          }}
          spacing="compact"
          icon={EditorCloseIcon}
          label="Rejected"
        />
      </Tooltip>
    </>
  ) : result === 'inClosed' ? (
    <CopyButton />
  ) : (
    <CopyButton />
  )
}

function copyToClipboard(text: string) {
  if (navigator.clipboard && window.isSecureContext) {
    // Використання API Clipboard, якщо доступний
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Text copied to clipboard:', text)
      },
      (err) => {
        console.error('Could not copy text: ', err)
      },
    )
  } else {
    // Використання fallback методу
    const tempInput = document.createElement('textarea')
    tempInput.style.position = 'absolute'
    tempInput.style.left = '-9999px'
    tempInput.value = text
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    console.log('Text copied to clipboard:', text)
  }
}
