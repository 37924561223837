// TODO: fix eslint errors
/* eslint-disable */
import React, { FC } from 'react'
import BaseModal from './BaseModal'

import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi'
import { RequestFromApi } from '../../../../../../shared/Calendar/Request/data/RequestFromApi'

import { TimeOffAccruedDaysForProfileFromApi } from '../../../../../../shared/Employee/Profile/JobInfo/data/TimeOffAccruedDaysForProfileFromApi'
import { EMPLOYEE } from '../../../../../../utils/constants/api'

interface EditAccruedDaysProps {
  opened: boolean
  requester: EmployeeFromApi | null
  accruedDays: TimeOffAccruedDaysForProfileFromApi[] | null
  accruedDay: TimeOffAccruedDaysForProfileFromApi | null
  onOk: () => void
  onClose: () => any
  onNewRequestCreated?: (request: RequestFromApi) => void
}

export const EditAccruedDays: FC<EditAccruedDaysProps> = (props) => {
  function create(timeOffPolicyId: string, quantity: number, comment: string): void {
    console.log(timeOffPolicyId, quantity, comment)
    const employeeId = props.requester?.id
    if (!employeeId) {
      console.error('Employee Id is not available')
      return
    }

    const formData = new FormData()
    formData.append('policyId', timeOffPolicyId)
    formData.append('daysToAllocate', String(quantity))
    formData.append('comment', comment)

    fetch(`${EMPLOYEE}/${employeeId}/time-off-accrued-days`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error, status = ${response.status}`)
        }
      })
      .then((json) => {
        props.onOk()
      })
      .catch((error) => console.error(error))
  }

  const getPersonName = (requester: typeof props.requester): string => {
    if (!requester) return ''
    if (requester.nickname) return ` ${requester.nickname}`
    return ` ${requester.firstNameEn}`
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle={`Edit balance for${getPersonName(props.requester)}`}
      okText="Save"
      accruedDays={props.accruedDays}
      accruedDay={props.accruedDay}
      onCancel={props.onClose}
      onOk={create}
    />
  )
}
