/* eslint-disable */
import Icon from '@ant-design/icons'
import type { GetProps } from 'antd'

type CustomIconComponentProps = GetProps<typeof Icon>

const SuccessSvg = () => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none">
    <g id="Icon / &#60;CheckCircleIcon&#62;">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M67.8332 37C67.8332 54.0287 54.0286 67.8333 36.9998 67.8333C19.9711 67.8333 6.1665 54.0287 6.1665 37C6.1665 19.9712 19.9711 6.16663 36.9998 6.16663C54.0286 6.16663 67.8332 19.9712 67.8332 37ZM28.9289 34.1291C29.3051 34.2907 29.6453 34.5256 29.9297 34.8201L33.9165 38.8069L44.0699 28.6534C44.3543 28.359 44.6946 28.1241 45.0708 27.9625C45.4469 27.8009 45.8515 27.7158 46.2609 27.7123C46.6703 27.7087 47.0763 27.7867 47.4553 27.9417C47.8342 28.0968 48.1785 28.3257 48.468 28.6152C48.7575 28.9047 48.9864 29.249 49.1414 29.6279C49.2965 30.0068 49.3745 30.4129 49.3709 30.8223C49.3674 31.2317 49.2823 31.6363 49.1207 32.0124C48.9591 32.3886 48.7242 32.7288 48.4297 33.0133L36.0964 45.3466C35.5182 45.9246 34.7341 46.2494 33.9165 46.2494C33.0989 46.2494 32.3148 45.9246 31.7366 45.3466L25.5699 39.1799C25.2754 38.8955 25.0405 38.5553 24.8789 38.1791C24.7173 37.8029 24.6323 37.3983 24.6287 36.9889C24.6252 36.5795 24.7032 36.1735 24.8582 35.7946C25.0132 35.4157 25.2422 35.0714 25.5317 34.7819C25.8212 34.4924 26.1655 34.2634 26.5444 34.1084C26.9233 33.9534 27.3293 33.8754 27.7387 33.8789C28.1481 33.8825 28.5527 33.9675 28.9289 34.1291Z"
        fill="#22A06B"
      />
    </g>
  </svg>
)

const ErrorSvg = () => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none">
    <g id="Icon / &#60;CrossCircleIcon&#62;">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.9998 67.8333C54.0286 67.8333 67.8332 54.0287 67.8332 37C67.8332 19.9712 54.0286 6.16663 36.9998 6.16663C19.9711 6.16663 6.1665 19.9712 6.1665 37C6.1665 54.0287 19.9711 67.8333 36.9998 67.8333ZM32.6579 37.0474L28.1106 41.6218C27.817 41.9071 27.5832 42.248 27.4228 42.6246C27.2623 43.0012 27.1785 43.406 27.1761 43.8153C27.1738 44.2247 27.253 44.6304 27.4091 45.0088C27.5652 45.3873 27.7951 45.7308 28.0853 46.0195C28.3756 46.3081 28.7205 46.536 29.0998 46.69C29.4791 46.844 29.8852 46.9209 30.2946 46.9162C30.7039 46.9115 31.1082 46.8254 31.4839 46.6628C31.8596 46.5003 32.1991 46.2645 32.4828 45.9693L37.0083 41.4168L41.446 45.8738C42.0229 46.4536 42.8065 46.7805 43.6245 46.7825C44.4424 46.7845 45.2276 46.4616 45.8074 45.8846C46.3872 45.3077 46.714 44.5241 46.7161 43.7062C46.7181 42.8883 46.3951 42.1031 45.8182 41.5233L41.3557 37.0434L45.9261 32.4458C46.4863 31.8627 46.7951 31.0829 46.7859 30.2744C46.7766 29.4659 46.4502 28.6933 45.8768 28.1232C45.3034 27.553 44.529 27.2309 43.7204 27.2263C42.9119 27.2216 42.1338 27.5348 41.5539 28.0983L37.0043 32.675L32.3748 28.0275C31.7945 27.4645 31.0163 27.152 30.2078 27.1572C29.3993 27.1625 28.6252 27.4851 28.0522 28.0556C27.4793 28.6261 27.1534 29.3989 27.1447 30.2074C27.136 31.0159 27.4452 31.7954 28.0058 32.3781L32.6579 37.0474Z"
        fill="#C9372C"
      />
    </g>
  </svg>
)

export const SuccessIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={SuccessSvg} {...props} />

export const ErrorIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ErrorSvg} {...props} />
