import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { announcementAPI, locationsAPI } from 'services/api'
import aiButtonReducer from './reducers/ai-button-slice'
import announcementsReducer from './reducers/announcements/announcements-slice'
import locationsReducer from './reducers/locations/locations-slice'

const rootReducer = combineReducers({
  aiButtonReducer,
  announcementsReducer,
  locationsReducer,
  [announcementAPI.reducerPath]: announcementAPI.reducer,
  [locationsAPI.reducerPath]: locationsAPI.reducer,
})

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([announcementAPI.middleware, locationsAPI.middleware]),
  })

export type RootStateType = ReturnType<typeof rootReducer>
export type AppStoreType = ReturnType<typeof setupStore>
export type AppDispatchType = AppStoreType['dispatch']
