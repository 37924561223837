import NoResultsSVG from "assets/images/policy/no_results.svg";
import {EmptyTableTemplate} from "../empty-table-tamplate";

export const NoResults = () => (
  <EmptyTableTemplate>
    <>
      <img src={NoResultsSVG} alt="No results" />
      <h2 className="title">No results found</h2>
      <h3 className="description">
        Sorry, that filter combination has no results.<br/> 
        Please try different criteria.
      </h3>
    </>
  </EmptyTableTemplate>
)
